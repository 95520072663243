.lookup-not-found-prompt {
  position: relative;
  color: orange;
  text-align: center;

  code {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: rgb(255, 60, 0);
    font-size: 16px;
    padding: 1px 5px 2px;
  }

  a {
    color: rgb(255, 60, 0);
    font-weight: 600;
  }
  > div {
    margin-bottom: 5px;
  }
}

.input-and-card-result {
  min-width: min(100%, 550px);
  max-width: 100%;
  flex-grow: 1;

  > div {
    padding: 10px 0;
  }
}

.lookup-uid-wrapper {
  .uid-lookup-result {
    padding: 10px;
    overflow: hidden;

    .scrollbar-container {
      width: 100%;
      display: grid;
      place-items: center;
    }

    .uid-result {
      width: 530px;

      .genshin-user-card {
        min-height: 102px;
      }
    }

    .fav-btn {
      display: none !important;
    }
  }
}

.justify-content-center {
  display: flex;
  justify-content: center;
}
