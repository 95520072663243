.highlight-tile-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  padding: 5px;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  $circle-size: 4px;
  $tile-height: 90px;

  &.expanded-showcase {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(80px, 100%), 1fr));
  }

  .is-new {
    box-shadow: inset 0 0 0 1px #40839b !important;
    background: linear-gradient(0deg, #40839b66 0%, transparent 80%);
  }

  .new-lb-badge {
    pointer-events: none;
    &::before {
      position: absolute;
      top: -5px;
      right: -10px;
      box-shadow: 0 0 5px 2px rgba(black, 0.5);
      scale: 0.8;
      z-index: 2;
    }
  }

  .mismatching-weapon {
    // opacity: 0.85;
    // background-color: #ff000020;
    box-shadow: inset 0 0 0px 1px #ff000020;
    position: relative;
    height: $tile-height;
    &::before {
      pointer-events: none;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, #ff000020 0%, transparent 80%);
      // content: "";
      // border-radius: 100px;
      // height: $circle-size;
      // width: $circle-size;
      // background-color: #ff0000dd;
      // position: absolute;
      // top: 2px;
      // right: 5px;
      // box-shadow: 0 0 0px 2px rgba(black, 0.3);
    }
    .table-icon-text-pair {
      // filter: drop-shadow(0 0 3px #ff0000);
      position: relative;
      // &::before {
      //   content: "";
      //   background-image: var(--icon-url);
      //   background-size: 25px 25px;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   z-index: -1;
      //   transform: scale(1.5);
      //   filter: grayscale(100%) brightness(100) ;
      // }
    }
    .highlight-tile:hover {
      box-shadow: inset 0 0 0 1px #ff000040;
    }
  }
  .almost-matching-weapon {
    // background-color: #ffee0020;
    box-shadow: inset 0 0 0px 1px #ffee0020;

    position: relative;
    height: $tile-height;
    &::before {
      pointer-events: none;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, #ffee0020 0%, transparent 80%);
      // content: "";
      // border-radius: 100px;
      // height: $circle-size;
      // width: $circle-size;
      // background-color: #ffee00dd;
      // position: absolute;
      // top: 2px;
      // right: 5px;
      // box-shadow: 0 0 0px 2px rgba(black, 0.3);
    }
    .table-icon-text-pair {
      // filter: drop-shadow(0 0 3px #ffee00);
    }
    .highlight-tile:hover {
      box-shadow: inset 0 0 0 1px #ffee0040;
    }
  }
  .matching-weapon {
    // background-color: #00ff0020;
    box-shadow: inset 0 0 0px 1px #00ff0020;
    position: relative;
    height: $tile-height;
    a::before {
      pointer-events: none;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, #00ff0020 0%, transparent 80%);
      // border-radius: 100px;
      // height: $circle-size;
      // width: $circle-size;
      // background-color: #00ff00dd;
      // position: absolute;
      // top: 2px;
      // right: 5px;
      // box-shadow: 0 0 0px 2px rgba(black, 0.3);
    }
    .table-icon-text-pair {
      // filter: drop-shadow(0 0 3px #00ff00);
    }
    .highlight-tile:hover {
      box-shadow: inset 0 0 0 1px #00ff0040;
    }
  }
  .highlight-tile {
    font-size: 13px;
    min-width: 75px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    text-decoration: none;
    color: #e7f6f2;
    padding: 0 2px;
    box-sizing: border-box;
    background-color: rgba(black, 0.15);
    > div {
      white-space: nowrap;
      margin: 0 2px;
    }
    &:hover {
      background-color: rgba(black, 0.35);
    }
    .highlight-tile-pill {
      padding: 2px;
      border-radius: 50px;
      font-weight: 800;
      white-space: nowrap;
      // font-family: "GenshinFont";

      &.stacked {
        width: 100%;
        text-align: center;
        height: 22px;
        position: relative;
        display: flex;
        flex-direction: column;
        .stacked-top {
          position: relative;
          top: -4px;
          transform: scale(0.9);
        }
        .stacked-bottom {
          position: relative;
          top: -7px;
          font-size: 10px;
        }
      }
    }
  }
}

.retrying-timer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 5px;
  .retrying-timer-label {
    opacity: 0.5;
    font-family: "GenshinFont";
    font-size: 14px;
  }
}
