.vm-container {
  position: relative;
  text-align: center;
  overflow: visible;
  z-index: 5 !important;
  margin: 10px 0;

  // width: 100%;
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  // padding: 10px;
  // padding: 0px !important;
  // margin: 20px 0;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  // background-color: #2c3333;

  // min-height: 250px;

  &.shrink-ad {
    // min-height: 0px;
    // padding: 0px;
    margin: 0px;
    z-index: 1000 !important;
  }

  span.ad-debug {
    display: none;
    color: red;
    font-size: 11px;
    position: absolute;
    top: -17px;
    left: 50%;
    width: 100%;
    pointer-events: none;
    transform: translateX(-50%);
  }
  // .vm-placement {
  //   transition: max-height 0.75s;
  //   max-height: 0px;
  //   &[data-google-query-id] {
  //     max-height: 300px;
  //   }
  // }
}

.flex-special-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  // width: 100%;
  // gap: 35px;
  flex-direction: row-reverse;

  gap: 10px;
  justify-content: center;
  align-items: flex-start;

  .other-calculations-display,
  .page-description-wrapper {
    margin: 0;
    flex: 1;
    // width: calc(100% - 450px);
    min-width: min(450px, 100%);
  }

  .vm-container {
    flex: 0;
    display: contents;
    .vm-placement {
      display: contents;
      > * > * {
        margin-left: 10px;
        @media (max-width: 800px) {
          margin-left: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}

// .vm-skin {
//   box-shadow: 0 0 2px 2px red;
// }

.vm-footer {
  // display: none !important;
  background-color: rgba(57, 91, 100, 0.6) !important;
  polygon {
    fill: white !important;
  }
  .vm-footer-close {
    &:hover {
      polygon {
        fill: orange !important;
      }
    }
  }
  @media (min-width: 600px) {
    padding-top: 0px !important;
  }
}

// @keyframes aaaa {
//   0% {
//     max-height: 0px;
//   }
//   70% {
//     max-height: 0px;
//   }
//   75% {
//     max-height: 400px;
//   }
//   100% {
//     max-height: 400px;
//   }
// }

// @keyframes bbbb {
//   0% {
//     top: 64px;
//   }
//   70% {
//     top: 64px;
//   }
//   75% {
//     top: 0px;
//   }
//   100% {
//     top: 0px;
//   }
// }

// @media (min-width: 800px) {
//   #top-of-the-page {
//     &.anim {
//       position: relative;
//       max-height: 0px;
//       animation: aaaa 7s linear;
//       animation-fill-mode: forwards;
//     }
//   }

//   .navbar-tabs {
//     &.anim {
//       top: 64px;
//       animation: bbbb 7s linear;
//       animation-fill-mode: forwards;
//     }
//   }

//   body:not(.overflow-hidden) {
//     .anim {
//       &#top-of-the-page,
//       &.navbar-tabs {
//         z-index: 1001;
//       }
//     }
//   }
// }

// body:not(.ads-disabled) {
//   #root > .flex-special-container {
//     min-height: 250px;
//   }
// }

body.ramp-js-error,
body.ads-disabled {
  .flex-special-container {
    // display: none !important;
    min-height: 0px !important;
  }
}
