.profile-header-wrapper.profile-header-wrapper {
  gap: 0px;

  .pw-container {
    &.video-ad-container {
      @media (max-width: 800px) {
        margin-top: 10px;
        width: 100%;
      }
      .playwire-ad-unit {
        .pw-tag > div > * {
          @media (max-width: 800px) {
            margin-top: 10px;
            margin-bottom: 0;
            margin-left: 0;
          }
        }
      }
    }
  }
}

.pw-container {
  position: relative;
  text-align: center;
  // overflow: visible;
  // z-index: 5 !important;
  margin: 0;

  &.video-ad-container {
    &::before {
      content: "";
      position: relative;
      width: 300px;
      height: 0px;
      display: block;
      top: 0;
      right: 0;
      pointer-events: none;
      z-index: 0;
      margin-left: 10px;
    }

    .playwire-ad-unit {
      .pw-tag > * {
        // background-color: rgba(0, 0, 0, 0.3);
        // box-shadow: inset 0 0 15px 15px rgba(0, 0, 0, 0.1);
        margin-left: 10px !important;

        @media (max-width: 800px) {
          margin-left: 0 !important;
          margin-bottom: 10px !important;
          display: block !important;
        }
      }
    }
  }

  span.ad-debug {
    display: none;
    color: red;
    font-size: 10px;
    position: absolute;
    top: -14px;
    left: 50%;
    width: 100%;
    pointer-events: none;
    transform: translateX(-50%);
  }

  .playwire-ad-unit {
    position: relative;
    // min-width: 350px;
    // min-height: 200px;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    // display: contents;

    // outline: 1px dashed rgba(red, 0.6);
  }
}

#root > .flex-special-container {
  min-height: 250px;
}

.flex-special-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0px;
  // margin-bottom: 20px;

  // min-height: 200px;
  min-height: 90px;

  // width: 100%;
  // gap: 35px;
  flex-direction: row-reverse;

  // gap: 10px;
  justify-content: center;
  align-items: flex-start;

  .other-calculations-display,
  .page-description-wrapper {
    margin: 0;
    flex: 1;
    // width: calc(100% - 450px);
    min-width: min(450px, 100%);
  }
}

.pw-footer {
  // display: none !important;
  background-color: rgba(57, 91, 100, 0.6) !important;
  polygon {
    fill: white !important;
  }
  .pw-footer-close {
    &:hover {
      polygon {
        fill: orange !important;
      }
    }
  }
  @media (min-width: 600px) {
    padding-top: 0px !important;
  }
}

body:not(.overflow-hidden) {
  #top-of-the-page {
    position: relative;
    z-index: 1002;
  }
  .navbar-tabs {
    position: relative;
    z-index: 1001;
  }
}

body.ramp-js-error,
body.ads-disabled {
  iframe:not(.html2canvas-container):not(#similarweb-outer-content),
  #bgdiv,
  #tyche_trendi_video_container,
  #tyche_trendi_video,
  #pw-oop-flex_leaderboard_container,
  #pw-oop-bottom_rail,
  #corner_ad_video,
  #aniBox,
  .sprkflw_main,
  .ut_container,
  .is-docked,
  .pw-oop-tag,
  .pw-pos-bottom-right,
  .pw-corner-ad-video,
  .pw-container,
  .pw-tag {
    display: none !important;
    opacity: 0 !important;
    pointer-events: none !important;
  }
  .flex-special-container {
    // display: none !important;
    min-height: 0px !important;
  }
}

// @media (max-width: 1300px) {
//   #pw-oop-left_rail,
//   #pw-oop-right_rail {
//     // playwire should make sure this is not needed, but in practice it can be wonky
//     display: none !important;
//     opacity: 0 !important;
//     pointer-events: none !important;
//   }
// }
