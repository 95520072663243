.theater-cell,
.theater-cell > span {
  display: flex;
  align-items: center;
  gap: 3px;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: var(--color);
}

.theater-badge {
  min-width: 30px;
  font-family: "GenshinFont";
  display: inline-block;
  white-space: nowrap;
  padding: 1px 4px;
  padding-right: 6px;
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: 0 0 3px rgba(black, 0.5);
  display: flex;
  justify-content: center;
  gap: 3px;
  background-color: var(--color);
  border-color: var(--color);

  &,
  & > span {
    color: white !important;
    text-shadow: none;
  }
}
