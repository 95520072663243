.news-section {
  padding: 20px;
  // background-color: #36393e;

  @media (max-width: 800px) {
    padding: 10px 5px;
  }

  .news-header {
    // text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
    // border-bottom: 1px solid rgba(black, 0.75);
    border-bottom: 1px solid rgba(white, 0.5);

    @media (max-width: 800px) {
      padding-top: 5px;
      padding-left: 15px;
      padding-bottom: 15px;
      margin-bottom: 10px;
    }

    svg {
      margin-right: 10px;
      opacity: 0.5;
    }
    .ch-desc {
      opacity: 0.5;
      font-size: 12px;
      margin-left: 3px;

      .ch-spacer {
        margin: 0 10px;
      }
    }
  }
}

.news {
  .discord-like-wrapper {
    display: flex;
    flex-direction: column;
    // gap: 10px;

    .discord-like-msg {
      padding: 10px;
      display: flex;
      gap: 15px;

      @media (max-width: 800px) {
        gap: 10px;
        font-size: 14px;
      }

      .animated-pfp {
        display: none;
      }

      &:hover {
        background-color: rgba(black, 0.15);

        .is-animated {
          .static-pfp {
            display: none;
          }
          .animated-pfp {
            display: block;
          }
        }
      }

      .discord-like-left {
        top: 2px;
        $pfp-size: 40px;

        @media (max-width: 800px) {
          $pfp-size: 35px;
        }

        position: relative;
        width: $pfp-size;
        height: $pfp-size;
        flex-shrink: 0;

        img {
          border-radius: 100%;
          width: $pfp-size;
          height: $pfp-size;
          position: absolute;
        }
      }

      .discord-like-right {
        width: 100%;
        .discord-like-username a,
        .discord-like-username {
          color: #ee90ea;
          display: flex;
          align-items: flex-end;
          gap: 10px;
          font-weight: 600;
          text-decoration: none;

          &:is(a) {
            &:hover,
            &:focus {
              text-decoration: underline;
            }
          }

          div:last-child {
            color: white;
            opacity: 0.5;
            font-size: 10px;
            margin-bottom: 2px;
          }
        }

        .discord-like-content {
          max-width: calc(100% - 40px);
          p {
            margin: 3px 0;
          }
          del {
            opacity: 0.5;
          }
          ul,
          ol {
            margin: 10px 0;
            @media (max-width: 800px) {
              padding-left: 25px;
            }
          }
          .discord-like-edited-at {
            color: white;
            opacity: 0.5;
            font-size: 10px;
            margin-bottom: 2px;
            margin-left: 5px;
            display: none;
          }

          a {
            color: orange;
          }

          hr {
            margin: 20px;
          }

          code {
            background-color: rgb(56, 56, 56);
            padding: 2px 4px;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
