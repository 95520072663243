.achievement-badge {
  font-family: "GenshinFont";
  display: inline-block;
  white-space: nowrap;
  // margin: 0 10px;
  // margin-right: 10px;
  padding: 1px 4px;
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: 0 0 3px rgba(black, 0.5);

  &.achievement-0-badge,
  &.achievement-100-badge,
  &.achievement-200-badge,
  &.achievement-300-badge,
  &.achievement-400-badge,
  &.achievement-500-badge,
  &.achievement-600-badge {
    background-color: rgb(128, 128, 128);
    border-color: rgb(128, 128, 128);
  }
  &.achievement-700-badge {
    background-color: rgb(75, 121, 71);
    border-color: rgb(75, 121, 71);
  }
  &.achievement-800-badge {
    background-color: rgb(31, 131, 31);
    border-color: rgb(31, 131, 31);
  }
  &.achievement-900-badge {
    background-color: rgb(0, 122, 122);
    border-color: rgb(0, 122, 122);
  }
  &.achievement-1000-badge {
    background-color: rgb(128, 45, 136);
    border-color: rgb(128, 45, 136);
  }
  &.achievement-1100-badge {
    background-color: rgb(168, 154, 31);
    border-color: rgb(168, 154, 31);
  }

  &.achievement-1200-badge,
  &.achievement-1300-badge,
  &.achievement-1400-badge,
  &.achievement-1500-badge {
    background-color: rgb(168, 120, 31);
    border-color: rgb(168, 120, 31);
  }

  display: flex;
  justify-content: center;
  gap: 3px;

  img {
    width: 20px;
    filter: drop-shadow(0 0 2px rgba(black, 0.7));
  }
}
